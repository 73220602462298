@media (max-width: 1200px) {
  #navbar-vertical {
    margin-top: 5px;
  }
}

#navbar-vertical {
  top: 4rem;
}

.btn-referidos {
  background-color: white !important;
}

.btn-referidos:hover {
  background-color: white !important;
}
