//
//
// user.scss
//
// Place your own theme CSS or SCSS rules below this line, these rules will override any Bootstrap and theme variables.

.referral-link {
  background: linear-gradient(45deg, #118df0, #00cfff, #020244);
  background-size: 400% 400%;
  animation: movingGradient 15s ease infinite;
  border-radius: 10px;
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;

  font-weight: 600;
}

.ai-btn {
  background: linear-gradient(45deg, #118df0, #020244);
  background-size: 400% 400%;
  animation: movingGradient 5s ease infinite;
  font-weight: 600;
}

@keyframes movingGradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
.pricing-card {
  max-width: 22rem !important;
}
.ticket {
  // background-color: #f8f9fa;
  border-radius: 15px !important;
  // padding: 1rem;
  // margin-bottom: 1rem;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border: #3071f2 2px dashed !important;
}
.bg-primary-light {
  background-color: #2c7ce528 !important;
}

.client-list-item :hover {
  background-color: #efeff0 !important;
}
.pac-container {
  z-index: 999999 !important;
}

.gpt-message h1 {
  font-size: 1.5rem;
  font-weight: 600;
}
.gpt-message h2 {
  font-size: 1rem;
  font-weight: 600;
}

.card-help {
  width: 25vw;
}

@media (max-width: 768px) {
  .card-help {
    width: 90vw;
  }
}
.circleAnim {
  animation: handAnim 3s infinite;
  height: 2rem;
  width: 2rem;
  display: flex;
  // justify-content: center;
  // align-items: center;
  // position: relative;
}
#pinata {
  animation: pinataAnim 5s infinite;
  // transform-origin: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  // overflow: hidden;
}

@keyframes pinataAnim {
  0% {
    // transform: translateY(10px);
    // transform: translateX(0px);
    // transform: rotateX(10deg);
  }
  50% {
    transform: translateY(-30px);
    // transform: translateX(-90px);
    transform: rotateZ(10deg);
  }
  100% {
    // transform: translateY(10px);
    // transform: translateX(0px);
    // transform: rotateX(-10deg);
  }
}

@keyframes handAnim {
  0% {
    // transform: translateY(10px);
    // transform: translateX(0px);
    // transform: rotateX(10deg);
  }
  50% {
    // transform: translateY(-30px);
    // transform: translateX(-90px);
    transform: rotateZ(30deg);
  }
  100% {
    // transform: translateY(10px);
    // transform: translateX(0px);
    // transform: rotateX(-10deg);
  }
}
.h100-of {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.faded {
  opacity: 0.5 !important;
}

.blurBgSeg {
  z-index: 2;
  text-align: left;
  border-radius: 15px;
  background-color: #00000043;
  color: white;
  backdrop-filter: blur(8px) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: 13%;
  right: -5%;
}

.blurBgPer {
  z-index: 2;
  text-align: left;
  border-radius: 15px;
  background-color: #00000043;
  color: white;
  backdrop-filter: blur(8px) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: 17%;
  left: -3%;
}

.d-none {
  display: none;
}

.blurBgRea {
  z-index: 2;
  text-align: left;
  border-radius: 15px;
  background-color: #00000043;
  color: white;
  backdrop-filter: blur(8px) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  bottom: 25%;
  right: -9%;
}

.blurBgRen {
  z-index: 2;
  text-align: left;
  border-radius: 15px;
  background-color: #00000043;
  color: white;
  backdrop-filter: blur(8px) !important;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  position: absolute;
  top: -12%;
  right: 50%;
}

.bubble {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s !important;
}

.bubble:hover {
  transition: cubic-bezier(0.075, 0.82, 0.165, 1) 2s !important;
  transform: scale(1.05) !important;
}

.border-right-lg {
  border-right: 2px solid #aaaaaa !important;
}

.border-right-lg::before {
  border-right: 2px solid #aaaaaa !important;
}

.plus-auto {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 50% !important;
  display: flex !important;
  margin: 0% !important;
  padding: 0% !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  right: -1.05rem !important;
}

.plus-auto:disabled {
  width: 2rem !important;
  height: 2rem !important;
  border-radius: 50% !important;
  display: flex !important;
  margin: 0% !important;
  padding: 0% !important;
  justify-content: center !important;
  align-items: center !important;
  position: absolute !important;
  right: -1.05rem !important;
  background-color: #6283c7 !important;
  color: #b2b2b2 !important;
  opacity: 1 !important;
}
.hvr-whitesmoke:hover {
  background-color: whitesmoke !important;
}
.chatbot {
  z-index: 99999999999999999;
}

.h-100i {
  height: 100% !important;
  flex-grow: 4;
}
.btn-registro {
  color: rgb(0, 111, 238);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 0.85rem;
}

.pro100 {
  height: 8rem;
}

.portalBtn {
  color: #cccfd2 !important;
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  text-decoration: none !important;
  padding-left: 1.5rem !important;
  font-size: 0.9rem !important;
}

@media (max-width: 500px) {
  .pro100 {
    height: 6rem;
  }
}
.btn-registro:hover {
  background-color: rgb(0, 111, 238);
  color: white;
}

.bg-none {
  background-color: rgba(0, 0, 0, 0) !important;
  border: none !important;
  text-decoration: none !important;
}
