@media (max-width: 1200px) {
  .banner {
    margin-top: 70px;
  }

  .closeButton {
    visibility: hidden;
  }
}

@media (min-width: 1200px) {
  .closeButtonLg {
    visibility: hidden;
  }
}

@media (max-width: 950px) {
  .closeButtonLg {
    margin-top: 0.5rem;
  }
}

.banner {
  background-color: #014ba7;
  padding: 15px;
  text-align: center;
  font-size: large;
  font-weight: bold;
  color: white;
  display: flex;
  justify-content: center;
}

.banner > p {
  margin: 0;
}

.closeButton {
  position: absolute;
  top: 0.3rem;
  right: 1.5rem;
  font-size: 1.7rem;
  cursor: pointer;
}
